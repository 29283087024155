.custom_card {
    background-color: #f1e4d5;
    border: none;
}

.news_big {
    border-right: none;
    border-left: none;
}

.custom_card_img {
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.custom_card_img_big {
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.loading-container {
    width: 100%;
    height: 160px;
    background-color: rgba(187, 187, 187, 0.3);
    border-radius: 10px;
}

.loading-container_big {
    width: 100%;
    height: 160px;
    background-color: rgba(187, 187, 187, 0.3);
    border-radius: 10px;

}



@media (min-width: 768px) {
    .news_big {
        margin-bottom: 10rem;
    }

    .custom_card_img {
        min-height: 140px;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .loading-container {
        min-height: 200px;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .custom_card_img_big {
        min-height: 350px;
        min-width: 450px;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .loading-container_big {
        width: 100%;
        height: 100%;
        object-fit: contain;
        min-height: 350px;
    }
}

@media (min-width: 992px) {
    .news_big {
        border-right: 1px solid rgba(15, 15, 15, 0.25);
        ;
        border-left: 1px solid rgba(15, 15, 15, 0.25);
        ;
        margin-bottom: 3rem;
    }
}