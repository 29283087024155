.bg-loading {
    background-color: rgba(187, 187, 187, 0.3);
    border: none;
}

.border-top-article {
    border-top: 1px solid rgba(15, 15, 15, 0.25);
}

.border-right {
    border-right: none;
}

.titolo_big_articolo {
    font-size: 14px;
}

.titolo_articolo{
    font-size: 14px;
}

@media (min-width: 768px) {
    .border-right {
        border-right: 1px solid rgba(15, 15, 15, 0.25);
    }

    .titolo_big_articolo {
        font-size: 2rem;
        line-height: 1.1;
    }
    .titolo_articolo{
        font-size: 1.25rem;
        line-height: 1.1;
    }
}