
.nav_custom {
    display: flex;

}
.border_nav{
    border-bottom: 1px solid white;
}
.logo{
    width: 70%;
    height: 50%;
}
@media (min-width: 576px) { 
    .logo{
        width: 225px;
        height: 100%;
    }
 }


@media (min-width: 768px) {  
    .stickybar {
        position: fixed;
        top: -30px;
        width: 100%;
        z-index: 1000;
      }
      .non-sticky-logo {
        position: absolute;
        top: -100%;
        transform: translateX(-50%);
      }
      .navbar-expand-lg .navbar-collapse {
        justify-content: center;
      }
}


@media (min-width: 992px) { 
    .nav_custom{
        display: flex;
        flex-direction: column;
    }
    .nav_custom_row{
        display: flex;
        flex-direction: column;
    }
    .nav_custom_first{
        border-bottom: 1px solid white;
        
    }
 }


@media (min-width: 1200px) {  }


@media (min-width: 1400px) {  }