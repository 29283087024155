.news_sub_container{
    border-top:  none;
}
.custom_sub_card_img{
    width: 100%;
    height: 160px;
    object-fit: cover;
}
.div_sub{
    height: 160px;
}

@media (min-width: 768px) {
    .custom_sub_card_img{
        width: 85%;
        height: 100%;
        object-fit: contain;
        min-height: 170px;
    }
    .div_sub{
        height: 230px;
    }
}
@media (min-width: 992px) { 
    .news_sub_container{
        border-top:  solid 1px rgba(15, 15, 15, 0.25);
    }
    .custom_sub_card_img{
        width: 100%;
        height: 180px;
        /* height: 100%; */
        object-fit: contain;
        /* min-height: 250px; */
    }
}