body {
  font-family: 'Serifa', sans-serif;
  background-color: #f1e4d5;
}
a{
  text-decoration: none;
  color: black;
}

.blu{
  color: red;
  font-size: 14px;
}