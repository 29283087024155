.custom_card_premium{
    width: 100%;
    height: 160px;
    object-fit: cover;
}
.custom_card_premium_big{
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.bg-custom_premium{
    background-color: white;
}

@media (min-width: 768px) {
    .custom_card_premium{
        /* width: 85%; */
        height: 100%;
        object-fit: contain;
        min-height: 140px;
    }
    .custom_card_premium_big{
        min-height: 250px;
        min-width: 450px;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

 
}
