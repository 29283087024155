.custom_card_pre{
    width: 100%;
    height: 160px;
    object-fit: cover;
}
.custom_card_pre-big{
    width: 100%;
    height: 160px;
    object-fit: cover;
}



@media (min-width: 768px) {
    .custom_card_pre{
        width: 85%;
        height: 100%;
        object-fit: contain;
        min-height: 170px;
    }
    .custom_card_pre_big{
        width: 85%;
        height: 350px;
        object-fit: contain;
        min-height: 170px;
    }

 
}
