.scopri{
    padding-top: 50px;
}
.img-div-nero{
    height: 100px;
}
@media (min-width: 576px) {  }


@media (min-width: 768px) { 
    .scopri{
        padding-top: 150px;
    }
    .img-div-nero{
        height: 120px;
    }
 }


@media (min-width: 992px) {  }


@media (min-width: 1200px) {  }


@media (min-width: 1400px) {  }