.category_img{
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.border_top{
    border-top: 1px solid rgba(15, 15, 15, 0.25);
}

.div_category{
    width: 100%;
}

@media (min-width: 768px) {
    .category_img{
        max-width: 650px;
        min-width: 650px;
        height: 100%;
        max-height: 350px;
        min-height: 300px;
    }

}
@media (min-width: 992px) {
    .div_category{
        width: 80%;
    }
}

