.custom_card {
    background-color: #f1e4d5;
    border: none;
}

.news_big {
    border-right: none;
    border-left: none;
}

.custom_card_img3 {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 0px;
}

.custom_card_img_big3 {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 0px;
}

.loading-container3 {
    width: 100%;
    height: 160px;
    background-color: rgba(187, 187, 187, 0.3);
    /* border-radius: 10px; */
}

.loading-container_big3 {
    width: 100%;
    height: 160px;
    background-color: rgba(187, 187, 187, 0.3);
    /* border-radius: 10px; */

}
.titolo_big3{
    font-size: 14px;
}

.titolo_news{
    font-size: 14px;
}

.text-start-md-text-center {
    text-align: start; 
  }

  .bordo-top {
      border: none;
  }


@media (min-width: 768px) {
    .news_big {
        margin-bottom: 10rem;
    }

    .custom_card_img3 {
        min-height: 150px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .loading-container3 {
        /* min-height: 200px; */
        width: 250px;
        height:150px;
        object-fit: contain;
    }

    .custom_card_img_big {
        min-height: 250px;
        min-width: 450px;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .loading-container_big3 {
        width: 100%;
        height: 100%;
        object-fit: contain;
        min-height: 250px;
        min-width: 450px;
    }
    .titolo_big3{
        font-size: 2rem;
        line-height: 1.1;
        text-align: center;
        /* text-decoration: underline; */
    }
    .titolo_news{
        font-size: 1.25rem;
        line-height: 1.1
    }

    .text-start-md-text-center {
        text-align: center; 
      }
    .bordo-top{
        border-top: 1px solid rgba(15, 15, 15, 0.25);
    }
}

@media (min-width: 992px) {
    .news_big {
        border-right: 1px solid rgba(15, 15, 15, 0.25);
        ;
        border-left: 1px solid rgba(15, 15, 15, 0.25);
        ;
        margin-bottom: 3rem;
    }
}